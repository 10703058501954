import React, { useState, useEffect } from 'react';
import './AnswerAnim.sass';

function AnswerAnim({ answerAnim, trigger1, trigger2 }) {
  const [objects, setObjects] = useState([]);

  function importAllImages(r) {
    let images = [];
    r.keys().map((item, index) => { images.push(r(item)); });
    return images;
  }
  
  const correctImages = importAllImages(require.context('./../media/correct', false, /\.(png|jpe?g|gif|svg)$/));
  const wrongImages = importAllImages(require.context('./../media/wrong', false, /\.(png|jpe?g|gif|svg)$/));

  useEffect(() => {
    if (trigger1 !== 0 || trigger2 !== 3) {
      const generateRandomPosition = () => {
        const minDist = 150;

        const minX = minDist; // Minimum distance from left border
        const minY = minDist; // Minimum distance from top border
        const maxX = window.innerWidth - minDist; // Maximum distance from right border
        const maxY = window.innerHeight - minDist; // Maximum distance from bottom border

        function getRandomNumber(min, max) {
          return Math.random() * (max - min) + min;
        }

        return { x: getRandomNumber(minX, maxX), y: getRandomNumber(minY, maxY) };
      };

      const chooseBackground = () => {
        return answerAnim ? 'correct' : 'wrong';
      }

      const chooseRandomGif = () => {
        const rightGifs = answerAnim ? correctImages : wrongImages;
        const randomIndex = Math.floor(Math.random() * rightGifs.length);
        return rightGifs[randomIndex];
      };

      const newObject = {
        position: generateRandomPosition(),
        gif: chooseRandomGif(),
        bg: chooseBackground()
      };

      setObjects(prevObjects => [...prevObjects, newObject]);

      const dissolveTimeout = setTimeout(() => {
        setObjects(prevObjects => prevObjects.filter(obj => obj !== newObject));
      }, 2500);

      return () => clearTimeout(dissolveTimeout);
    }
  }, [trigger1, trigger2]);
  return (
    <>
      {objects.map((obj, index) => (
        <div key={index}>
          <div className={`answer-anim-bg ${obj.bg}`}></div>
          <img
            key={index}
            className="dissolving-object"
            src={obj.gif}
            style={{ left: obj.position.x, top: obj.position.y }}
          />
        </div>
      ))}
    </>
  );
}

export default AnswerAnim;
